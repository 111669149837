/*
 * 업무구분: 고객
 * 화 면 명: MSPCM210M
 * 화면설명: 내그룹
 * 작 성 일: 2023.04.04
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="내그룹" :show-title="true" type="subpage" @on-header-left-click="fn_HistoryBack" :action-type="isMngr && isSearched? actionSlotMngrSrch: isMngr? actionSlotMngr: actionSlot" :topButton="true">
    <template #frame-header-fixed>
      <!-- 검색결과 -->
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData210M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData210M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData210M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData210M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData210M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData210M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData210M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData210M.cnslt.label}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <!-- 총 건수 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{totCnt}}</strong> 건</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isEmptyView" alignV="start" componentid="" direction="column" class="ns-check-list">  
        <mo-list :list-data="cmCustGrpListSVO">
          <template #list-item="{item}">
            <mo-list-item>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip" @click="fn_MoveMSPCM212M(item)">{{item.chnlCstgrNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span><span class="crTy-bk7 fs14rem mr10">생성일</span><span>{{$commonUtil.dateDashFormat(item.regYmd)}}</span><em>|</em></span><span><span class="crTy-bk7 fs14rem mr10">고객수</span><span>{{item.grpCustFgr}}</span></span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list> 
      </ur-box-container>
      <!-- 그룹이 없을때 보여짐 -->
      <ur-box-container v-else alignV="start" componentid="" direction="column" class="no-data no-make-group">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info small-space">
              <span class="ns-ftcr-gray"><p class="mt10">새 그룹을 생성해주세요</p></span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </ur-box-container>
    
    <mo-bottom-sheet ref="bottomSheetNew" :close-btn="false" :show="true" class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        새 그룹 생성
        <div class="ns-btn-close" @click="fn_InitBottomSheet" name="닫기"></div>
      </template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="" direction="column" class="sch-box">
          <mo-text-field class="form-input-name" underline clearable placeholder="그룹명 입력" v-model="lv_ChnlCstgrNm" :maxlength="isOverByte? lv_RalMaxLength: '30'" ref="newChnlCstgrNm" :rules="validateRuleRequire"/>
        </ur-box-container>
      </ur-box-container>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_InitBottomSheet" class="ns-btn-round white">취소</mo-button>
            <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_SubmitP" :disabled="!validateRslt" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPCM116P from '@/ui/cm/MSPCM116P' // 그룹검색
import Screen from '~systems/mixin/screen'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPCM210M",
  screenId: "MSPCM210M",
  mixins: [Screen],
  components: {
    MSPCM116P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    let lv_Vm = this;
    this.actionSlot = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button @click="fn_OpenBs()" shape="border" class="ns-btn-round btn-title-style-1">새그룹</mo-button></div>',
        methods: {
          fn_OpenBs() {
            lv_Vm.fn_OpenBs()
          }
        }
      }
    }
    this.actionSlotMngr = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-button @click="fn_OpenBs()" shape="border" class="ns-btn-round btn-title-style-1">새그룹</mo-button><mo-icon type="picto" @click="fn_OpenMSPCM116P">search</mo-icon></div>',
        methods: {
          fn_OpenBs() {
            lv_Vm.fn_OpenBs()
          },
          fn_OpenMSPCM116P() {
            lv_Vm.fn_OpenMSPCM116P()
          }
        }
      }
    }
    this.actionSlotMngrSrch = {
      actionComponent: {
        template:'<div slot="action" class="icon-wrapper"><mo-icon type="picto" @click="fn_OpenMSPCM116P">search</mo-icon></div>',
        methods: {
          fn_OpenMSPCM116P() {
            lv_Vm.fn_OpenMSPCM116P()
          }
        }
      }
    }

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_inputParamS.cnsltNo = this.userInfo.userId // 컨설턴트번호
    if (this.$cmUtil.isSrchOrgCombo() === 'Y') {  // 지점장 여부
      this.isMngr = true
    }
    // 지점장: 내그룹 상세에서 돌아왔을 경우
    if (this.isMngr && this.$route.params.pPage === 'MSPCM212M') {
      this.isFirst = false
      this.pOrgData210M = this.$route.params.pOrgData210M
      if (!_.isEmpty(this.pOrgData210M)) this.isSearched = true
    }

    // 내그룹 목록 조회
    this.fn_Submit()
  },

  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    this.fn_InitBottomSheet()
  },

  computed: {
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData210M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    },
    byteLength () {
      return this.fn_GetStringByte(this.lv_ChnlCstgrNm)
    },
    validateRslt () {
      let val = this.lv_ChnlCstgrNm

      if (this.$bizUtil.isEmpty(val)) {
        this.validateMsg = '그룹명을 입력해주세요.'
        return false
      } else if (this.cmCustGrpListSVO.find(e => e.chnlCstgrNm === val)) {
        this.validateMsg = '동일한 그룹명이 존재합니다.'
        return false
      } else if (this.byteLength > 40 || val.length > 30) {
        this.validateMsg = '그룹명은 한글 20자(영문 30자) 이상 입력할 수 없습니다.'
        return false
      } else return true
    }
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      MAX_BYTE: 40,
      validateRuleRequire: [
        (v) => this.validateRslt || this.validateMsg,
      ],

      // 제어 변수
      isSearched: false,    // 검색이력
      isFirst: true,        // 초회조회여부
      isInit: false,        // 검색 초기화 여부
      isEmptyView: false,   // 데이터가 없습니다 visible 여부
      isMngr: false,        // 지점장 여부
      isLoading: false,     // 서비스 실행중 여부
      isOverByte: false,    // 바이트 체크 

      // 데이터 변수
      pOrgData210M: {},     // 최근 조직 조회 데이터
      userInfo: {},         // 사용자 정보
      popup116: {},         // MSPCM116P 전체고객 목록 검색
      lv_ReturnStr: [],
      cmCustGrpListSVO: [], // 인피니트 바인딩 리스트VO(그룹목록)
      lv_inputParamS: {     // 내그룹 조회 서비스호출 파라미터
        cnsltNo: ''         // 컨설턴트번호
      },
      totCnt: 0,
      stndKeyList: [],      // 더보기 key
      pageRowCnt: '200',    // 페이지 카운트
      stndKeyValS: 'start',
      lv_ChnlCstgrNm: '',   // 새 그룹명
      lv_inputParamP: {     // 새그룹 생성 서비스호출 파라미터
        cnsltNo: '',        // 컨설턴트번호
        chnlCstgrNm: '',    // 그룹명
        chnlCstgrId: null,  // 그룹아이디
        iCCstgrRltnVO: []   // 그룹에 추가된 고객(미사용)
      },
      lv_RalMaxLength: 9999,
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색
        // 초기화
        this.pOrgData210M = {}
        this.isSearched = false // 검색이력
        this.isInit = true
        this.isFirst = true
        this.fn_InitBottomSheet() // 바텀시트

        // 재조회
        this.fn_Submit()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetNew !== undefined) {
          lv_Vm.$refs.bottomSheetNew.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 내그룹 목록 조회
     ******************************************************************************/
    fn_Submit () {
      // 초기화
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.totCnt = 0
      this.cmCustGrpListSVO = []
      this.stndKeyList = []
      this.pageRowCnt = '200'
      this.stndKeyValS = 'start'

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_DataSetS() // 조회 파라미터 세팅
      this.fn_GetServiceS() // 서비스 호출
    },

    /******************************************************************************
      * Function명 : fn_DataSetS
      * 설명       : 그룹 조회전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetS () {
      if (this.isFirst) {
        this.pOrgData210M = {}  // 상세조회조건
        this.lv_inputParamS.cnsltNo = this.userInfo.userId // 컨설턴트번호
        this.lv_inputParamS.dofOrgNo = this.userInfo.onpstDofNo
        this.lv_inputParamS.fofOrgNo = this.userInfo.onpstFofOrgNo
        this.lv_inputParamS.hofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호
      }

      if (!_.isEmpty(this.pOrgData210M) && this.isMngr === true) {
        this.lv_inputParamS.cnsltNo = this.pOrgData210M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParamS.cnsltNo = this.userInfo.userId // 사용자ID
      }
    },

    /******************************************************************************
      * Function명 : fn_GetServiceS
      * 설명       : 서비스 호출(내그룹 목록 조회)
    ******************************************************************************/
    fn_GetServiceS () {
      if (this.stndKeyValS === 'end') return
      const trnstId = 'txTSSCM35S1'// 내그룹 목록 조회
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamS

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.icgrpInfoVO
            if (responseList !== null) {
              if (lv_Vm.stndKeyValS !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmCustGrpListSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmCustGrpListSVO = responseList
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmCustGrpListSVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyValS = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyValS = 'more'
              }

              if (lv_Vm.totCnt === 0) lv_Vm.isEmptyView = true
              else lv_Vm.isEmptyView = false
            } else {
              lv_Vm.isEmptyView = true
            }
          } else {
            lv_Vm.$addSnackbar(response.msgComm.msgDesc)
            lv_Vm.isEmptyView = true
          }
          lv_Vm.isLoading = false
          lv_Vm.isFirst = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명: fn_OpenBs
     * 설명: 새그룹 생성 바텀시트 오픈
     ******************************************************************************/
    fn_OpenBs () {
      let lv_Vm = this

      lv_Vm.$refs.bottomSheetNew.open()
      lv_Vm.$nextTick(() => {
        lv_Vm.$refs.newChnlCstgrNm.focus()
      })
    },

    /******************************************************************************
      * Function명 : fn_SubmitP
      * 설명       : 새 그룹 생성 확인 버튼 클릭 이벤트
    ******************************************************************************/
    fn_SubmitP () {
      this.fn_DataSetP() // 파라미터 세팅
      this.fn_GetServiceP() // 서비스 호출
    },

    /******************************************************************************
      * Function명 : fn_DataSetP
      * 설명       : 저장전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetP () {
      this.lv_inputParamP.cnsltNo = this.userInfo.userId // 컨설턴트번호
      this.lv_inputParamP.chnlCstgrNm = this.lv_ChnlCstgrNm // 그룹명
      this.lv_inputParamP.chnlCstgrId = null // 그룹아이디
      this.lv_inputParamP.iCCstgrRltnVO = null
    },

    /******************************************************************************
      * Function명 : fn_GetServiceP
      * 설명       : 서비스 호출(그룹 저장)
    ******************************************************************************/
    fn_GetServiceP () {
      const trnstId = 'txTSSCM35P1'// 그룹저장
      const auth = 'S'

      let lv_Vm = this
      let pParams = this.lv_inputParamP
      let confirm = this.getStore('confirm')
      if (this.isLoading) return

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.cMAllCustBasSVO
            lv_Vm.lv_ChnlCstgrNm = ''
            lv_Vm.$addSnackbar(lv_Vm.$t('co')['ECOC012']) // 저장되었습니다
            // 바텀시트 초기화
            lv_Vm.fn_InitBottomSheet()
            // 리스트 재조회
            lv_Vm.fn_Submit()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명  : fn_GetStringByte()
    * 설명        : 스트링 값에 따른 데이터베이스 바이트 계산
    * Params      : N/A
    * Return      : 스트링 값에 따른 데이터베이스 바이트.
    ******************************************************************************/
    fn_GetStringByte (pStr) {
      let int_char_cnt = 0
      if (!this.$bizUtil.isEmpty(pStr)) {
        let contents = pStr
        let ralLength = 0
        this.isOverByte = false
        this.lv_RalMaxLength = 9999

        for (let i = 0; i < contents.length; i++) {
          let str_char = escape(contents.charAt(i))

          if (str_char.length === 1) {
            int_char_cnt += 1
          } else if (str_char.indexOf('%u') !== -1) {
            int_char_cnt += 2
          } else if (str_char.indexOf('%') !== -1) {
            int_char_cnt += str_char.length / 3
          }

          if (int_char_cnt <= this.MAX_BYTE) {
            ralLength = i+1
          }
        }
  
        if (int_char_cnt > this.MAX_BYTE) {
          this.isOverByte = true
          this.lv_RalMaxLength = ralLength
          this.lv_ChnlCstgrNm = this.lv_ChnlCstgrNm.substr(0, ralLength)
        }
  
        return int_char_cnt
      }
      else return 0
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      let lv_Vm = this

      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: this.$options.screenId,
          pOrgData: this.pOrgData210M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            lv_Vm.$bottomModal.close(lv_Vm.popup116)
            lv_Vm.fn_InitBottomSheet()

            // 마지막 조직 조회 데이터 셋팅
            lv_Vm.pOrgData210M = pData.rtnData.pOrgData

            // 조회이력 저장
            lv_Vm.isSearched = true
            // 검색 초기화 여부
            lv_Vm.isInit = pData.isInit
            if (lv_Vm.isInit) {
              lv_Vm.isSearched = false
              lv_Vm.pOrgData210M = {}
            }
            // 조회조건 텍스트 세팅
            lv_Vm.lv_ReturnStr = pData.srchOpt

            // 내그룹 조회
            lv_Vm.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명: fn_MoveMSPCM212M
     * 설명: 그룹 상세 화면으로 이동
     ******************************************************************************/
    fn_MoveMSPCM212M(item) {
      this.$router.replace(
        // params: 선택한 아이템, 조직조회정보, 내그룹 리스트(그룹명 편집 시 그룹명 중복검사)
        { name: 'MSPCM212M', params: {item: item, pOrgData210M: this.pOrgData210M, pCmCustGrpListSVO: this.cmCustGrpListSVO} }
      )
    }
  }
};
</script>
<style scoped>
</style>